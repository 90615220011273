<template>
  <v-list>
    <v-list-item v-for="item in invoicesAndPayments" :key="item.type + item.id">
      <v-list-item-icon>
        <v-icon v-if="item.type === 'invoice'" color="red">fal fa-file</v-icon>
        <v-icon v-else color="green">{{ getIconBySource(item.source) }}</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title
          v-if="item.type === 'invoice'"
          @click="getInvoicePdf(item.id)"
          >Rechnung
          <template v-if="item.type === 'invoice'"
            >RE-SM-{{ item.date | formatYear }}-{{ item.number }}</template
          >
        </v-list-item-title>
        <v-list-item-title v-else>{{ item.information }}</v-list-item-title>
        <v-list-item-subtitle>
          {{ item.date | formatDate }} |
          {{ item.value | formatCurrency }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-list-item v-if="items.length > 6">
      <v-list-item-content @click="limitItems = !limitItems">
        <v-btn text v-if="limitItems">Weitere anzeigen</v-btn>
        <v-btn text v-else>Weniger anzeigen</v-btn>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
import apiService from "@/common/api.service";
export default {
  name: "PaymentHistory",
  props: ["booking"],
  data() {
    return {
      invoices: [],
      items: [],
      limitItems: true
    };
  },
  computed: {
    invoicesAndPayments() {
      const invoicesAndPayments = [...this.items];
      return this.limitItems
        ? invoicesAndPayments.splice(0, 6)
        : invoicesAndPayments;
    }
  },
  methods: {
    getInvoicesAndPayments: function() {
      const vue = this;
      const bookingId = this.booking.id;
      let invoices = [];
      let payments = [];
      let items = [];
      vue.$store.getters["invoices/getByBooking"](bookingId).forEach(
        invoice => {
          invoices.push(Object.assign({ type: "invoice" }, invoice));
        }
      );

      vue.$store.getters["payments/getByBooking"](bookingId).forEach(
        payment => {
          payments.push(Object.assign({ type: "payment" }, payment));
        }
      );
      items = invoices.concat(payments);
      this.items = items.sort((a, b) => (a.date < b.date ? 1 : -1));
    },
    getIconBySource: function(source) {
      if (source === "paypal") return "fal fa-paypal";
      if (source === "bank" || source === "bank-import")
        return "fal fa-landmark";
      if (source === "coupon") return "fal fa-money-check";
      if (source === "internal") return "fal fa-exchange";
    },
    getInvoicePdf: function(id) {
      const vue = this;
      apiService
        .query("/invoices/" + id + "/pdf", { responseType: "blob" })
        .then(response => {
          if (response.status === 200) {
            const invoice = vue.$store.getters["invoices/getById"](id);
            const dateArray = invoice.date.split("-");
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              "RE-SM-" + dateArray[0] + "-" + invoice.number + ".pdf"
            );
            document.body.appendChild(link);
            link.click();
          }
        });
    }
  },
  watch: {
    booking: function() {
      this.getInvoicesAndPayments();
    }
  },
  created() {
    this.getInvoicesAndPayments();
  }
};
</script>

<style scoped></style>
